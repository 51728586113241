import type React from 'react'
import type { ReactNode } from 'react'
import { useTranslation } from 'next-i18next'

import { FALLBACK_IMAGES } from '@cms/utils/constants'
import { getImagesWithFallback } from '@cms/utils/utils'
import { ImageGalleryTextCombo } from '@components/ImageGalleryTextCombo'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import type { ImageWeb } from '@knauf-group/ct-designs/utils/types'
import Typography from '@mui/material/Typography'

import RichTextList from '../RichTextList'
import type {
  ImageGalleryTextBodyWrapperProps,
  ImageGalleryTextComboWrapperProps,
} from './ImageGalleryTextComboWrapper.types'

export const ImageGalleryTextBody: React.FC<ImageGalleryTextBodyWrapperProps> = ({
  richTextDocument,
  dataCy,
}) => {
  type ParagraphProps = {
    children?: ReactNode
  }

  const Paragraph: React.FC<ParagraphProps> = ({ children }) => (
    <Typography variant="body2" data-cy={`${dataCy}-paragraph`}>
      {children}
    </Typography>
  )

  const richTextRenderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph>{children}</Paragraph>,
      [BLOCKS.LIST_ITEM]: (_node, children) => {
        return <>{children}</>
      },
      [BLOCKS.OL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
      [BLOCKS.UL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
      [BLOCKS.HEADING_2]: (_node, children) => (
        <Typography variant="h2" data-cy={`${dataCy}-sub-headline-two`}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <Typography variant="h4" data-cy={`${dataCy}-sub-headline-four`}>
          {children}
        </Typography>
      ),
    },
  }

  return <>{documentToReactComponents(richTextDocument, richTextRenderOptions)}</>
}

const ImageGalleryTextComboWrapper: React.FC<ImageGalleryTextComboWrapperProps> = ({
  dataCy = 'image-gallery-text-combo',
  ...props
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'common.slider' })

  const isProductImage = Boolean(props?.fields?.productImage)
  const images = props.fields?.images as ImageWeb[]

  const imagesFormatted = getImagesWithFallback(images, FALLBACK_IMAGES.DEFAULT_16_9_RATIO)

  return (
    <ImageGalleryTextCombo
      isProductImage={isProductImage}
      images={imagesFormatted}
      dataCy={dataCy}
      backLabel={t('backButtonLabel')}
      nextLabel={t('nextButtonLabel')}
    >
      <ImageGalleryTextBody richTextDocument={props.fields?.text} />
    </ImageGalleryTextCombo>
  )
}

export default ImageGalleryTextComboWrapper
