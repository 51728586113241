import type React from 'react'
import Image from 'next/image'

import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import { SMART_CROP_TOKENS } from '@cms/utils/constants'
import {
  getDevicePixelRatioPriority,
  getRenditionImageLoader,
  getSmartCropImageLoaderByTokenName,
} from '@cms/utils/utils'
import type { CarouselStyles } from '@knauf-group/ct-designs/components/core/Carousel'
import { Carousel } from '@knauf-group/ct-designs/components/core/Carousel'
import { ImageWrapper } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { ScreenXS } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

import type { ImageGalleryProps } from '.'

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  isProductImage = false,
  backLabel,
  nextLabel,
  dataCy = 'image-gallery',
}) => {
  const theme = useTheme()
  const isScreenXs = ScreenXS(theme)
  const { devicePixelRatio } = useDevicePixelRatio()

  const styles: Styles = {
    container: {
      width: '100%',
    },
    imageMain: {
      width: '100%',
      height: isProductImage ? 'auto' : '100%',
      objectFit: isProductImage ? 'contain' : 'cover',
    },
    imageThumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }

  // TODO a clean up on leaving only what needs to be overwriten here and move the rest to Slider.styles
  const sliderStyles: CarouselStyles = {
    embla: {
      '&.embla': {
        maxWidth: '100%',
        borderTop: 0,
        borderBottom: 0,
      },
      '& .embla__slide__inner': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        aspectRatio: '16/9',
        '*': {
          width: '100%',
          position: 'absolute',
          display: 'block',
          minHeight: '100%',
          minWidth: '100%',
          maxWidth: 'none',
          objectFit: 'contain',
          aspectRatio: '16/9',
        },
      },
    },
    emblaDots: {
      '& .embla__dot.is-selected': {
        width: '24px',
        height: '8px',
      },
      '& .embla__button': {
        position: 'relative',
        bottom: '70px',
        backgroundColor: theme.palette.text.secondary,
        opacity: '50%',
      },
      '& .embla__button--prev': {
        left: '10px',
      },
      '& .embla__button--next': {
        right: '12px',
      },
    },
    emblaThumbs: {
      [`&:dir(rtl) .${svgIconClasses.root}`]: {
        transform: 'rotate(180deg)',
      },
    },
  }

  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  // Loader for the Thumbnail images(small ones in the bottom list of the component)
  const imageLoaderThumbnail = getSmartCropImageLoaderByTokenName(
    SMART_CROP_TOKENS.CF_16X9_S,
    devicePixelRatioPriority,
  )

  // Loader for the Main image(big one)
  const imageLoaderMain = isProductImage
    ? getRenditionImageLoader(devicePixelRatioPriority)
    : getSmartCropImageLoaderByTokenName(SMART_CROP_TOKENS.CF_16X9_L, devicePixelRatioPriority)

  const imageSlides = images?.map((image, index) => (
    <ImageWrapper
      image={image}
      nextImageComponent={Image}
      // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
      loader={imageLoaderMain}
      sx={styles.imageMain}
      data-cy={`${dataCy}-main-image`}
      key={`main-image-${image.title}-${index}` as React.Key}
      // Due to issues with lazy loading with RTL in chrome, this is a workaround. See https://github.com/davidjerleke/embla-carousel/discussions/736#discussioncomment-8476971
      priority={theme.direction === 'rtl'}
    />
  ))

  const thumbnailSlides = images?.map((image, index) => (
    <ImageWrapper
      image={image}
      nextImageComponent={Image}
      loader={imageLoaderThumbnail}
      sx={styles.imageThumbnail}
      data-cy={`${dataCy}-thumbnail`}
      key={`thumbnail-image-${image.title}-${index}` as React.Key}
    />
  ))

  return (
    <Box sx={styles.container} data-cy={dataCy}>
      {images?.length > 0 &&
        (isScreenXs ? (
          <Carousel
            slides={imageSlides}
            carouselStyles={sliderStyles}
            isCounter
            isStepper
            backLabel={backLabel}
            nextLabel={nextLabel}
          />
        ) : (
          <Carousel
            slides={imageSlides}
            thumbnailSlides={thumbnailSlides}
            carouselStyles={sliderStyles}
            isThumbs
          />
        ))}
    </Box>
  )
}

export default ImageGallery
